@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300italic,700,700italic);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display:700italic);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300italic,700,700italic);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display:700italic);
/*All styles*/
html, body, header, footer, aside, article, p, h1, h2, h3, h4, div {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: #555;
    margin: 0;
    padding: 0;
}

article, aside, nav, section, header, footer {
    display: block;
}

#page {
    max-width: 1250px;
    min-height: 700px;
    margin: 0 auto;
    position: relative;
    background-color: white;
}

.page {
    max-width: 1250px;
    margin: 0 auto;
    position: relative;
    background-color: white;
    padding: 0;
}

.borderis {
    border-bottom: 1px solid black;
    margin: 0;
    clear: both;
}

.full-page {
    width: 100%;
    background-color: rgb(240, 240, 240);
    padding: 0;
    margin: 0;
}

/*Header -------------------------------------------------------------------------------------*/
#first-page {
    height: 600px;
    background: url(/static/media/homeBanner1920.b1c37c6e.png) no-repeat;
}

/*	background-size: contain; */

.logo {
    min-width: 260px;
    position: absolute;
    top: 20px;
    left: 20%;
    z-index: 1;
    padding: 10px;
    text-align: left;
}

.logo h1, h2 {
    color: #d9d0bd;
    font-weight: 500;
}

.logo h2 {
    font-style: italic;
    padding-left: 0;
    margin-left: 1px;
    position: relative;
    left: 0;
}

.advert {
    position: absolute;
    top: 320px;
    right: 0;
    border: 2px solid white;
    border-right: 0;
    padding: 20px;
    background-color: rgba(0, 0, 0, .7);
    width: 42%;
}

.advert h1 {
    line-height: 1.2em;
    margin: 5px;
    color: white;
}

.advert h2 {
    font-style: italic;
    display: inline-block;
    padding: 2px 30px;
}

/*Navigation -----------------------------------------------------------------------------------------*/

nav {
    background-color: rgba(0, 0, 0, .7);
    position: absolute;
    top: 0;
    left: 0;
    padding: 50px 0 0 2%;
    width: 98%;
    font-size: 1.4em;
}

nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

nav ul li:hover {
    background-color: rgba(255, 255, 255, .30);
    transition: background-color .65s;
    z-index: 10;
}

nav ul li:hover > ul {
    transition-delay: .65s;
    display: block;
}

nav ul li a {
    display: inline-block;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    position: relative;
    width: 110px;
}

nav ul li a:visited {
    color: #fff;
}

nav ul li:hover {
    background-color: rgba(255, 255, 255, .30);
    transition: background-color .65s;
}

nav ul ul {
    position: absolute;
    top: 100%;
    background-color: rgba(0, 0, 0, .65);
    display: none;
}

nav ul ul li {
    position: relative;
}

nav ul ul ul {
    left: 100%;
    top: 0;
}

/* Top-level-menu-buttons*/

nav > ul {
    padding-left: 45%;
    padding-top: 15px
}

nav > ul > li {
    float: left;
}

nav > ul > li > a {
    width: auto;
    padding: 10px 20px 15px 20px;
}

nav::after {
    content: '';
    display: block;
    clear: both;
}

nav a[aria-haspopup="true"]::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: auto;
    right: auto;
    bottom: 6px;
    left: 45px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid white;

}

/*Service-list sections ------------------------------------------------------------------------- */

section.servicelist {
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 0;
}

section.servicelist::after {
    content: '';
    display: block;
    clear: both;
}

section.servicelist aside {
    width: 33%;
    float: left;
    text-align: center;
}

section.servicelist aside p {
    margin-top: 10px;
    text-align: center;
}

/*Section Service List inside Elements ------------------------------------------------------------*/

div.service {
    margin: 15px;
    padding-right: 20px;
    padding-left: 10px;
    background: #fafafa no-repeat center top;
    background-size: 75px 75px;
    height: 250px;
    transition: .3s;
}

.service:hover {
    background-color: rgba(0, 0, 0, .1);
}

.service h3 {
    padding-top: 5px;
}

div.service a {
    color: rgba(0, 0, 0, .7);
    text-decoration: none;
}

div.service a:hover {
    text-decoration: underline;
}

/* Icons service section elements--------------------------------------------------------------*/
.icon {
    padding: 20px;
}

.icon2 {
    color: rgba(0, 0, 0, .8);

}

/*Startups Section Banner 1 -------------------------------------------------------------*/

.startups {
    height: 480px;
    clear: both;
    margin: 50px auto 0;
    background: rgb(240, 240, 240) url(/static/media/startups.31c99bbf.png) no-repeat top 40px left 550px;
}

div.startups-text {
    height: 150px;
    width: 38%;
    text-align: justify;
    color: black;
    padding: 10px;
    position: relative;
    top: 80px;
    left: 30px;
}

div.startups-text h1 {
    font-size: 1.5em;
    font-family: 'Open Sans';
    font-weight: 500;
    text-align: left;
    padding: 5px;
    display: block;
    border-bottom: 1px solid black;
}

div.startups-text h1 a {
    text-decoration: none;
    color: black;
}

div.startups-text p {
    padding: 20px 20px 20px 0;
}

div.startups-text a {
    color: black;
    text-decoration: none;
}

/*Tax Section Banner 2---------------------------------------------------*/

.tax {
    height: 500px;
    clear: both;
    margin: 20px auto 50px;
    background: url(/static/media/terry-cpa-accounting.941963d7.png) no-repeat;
}

div.tax-text {
    height: 160px;
    width: 40%;
    text-align: justify;
    padding: 10px 10px 10px 20px;
    position: relative;
    top: 180px;
    left: 55%;
    background-color: rgba(10, 10, 10, .7);
    border-radius: 5px;
}

div.tax-text h1 {
    font-size: 2.8em;
    font-family: 'Playfair Display', serif;
    text-align: center;
    display: block;
    color: rgba(250, 250, 250, .9);
}

#tax-log {
    font-family: 'Playfair Display', serif;
    color: #d9d0bd;
    font-size: 1.8em;
    border-bottom: 1px solid #d9d0bd;
    text-align: left;
    padding-bottom: 5px;
}

/*Footer---------------------------------------------------------*/

.page-footer {
    height: 80px;
    color: white;
    background-color: rgba(0, 0, 0, .8);
    width: 100%;
    text-align: center;
}

.page-footer p {
    padding-top: 10px;
    text-decoration: underline;
}

.page-footer1 {
    max-width: 1250px;
    margin: 0 auto;
    padding-left: 50px;
    font-size: 1.2em;
}

.page-footer h2 {
    padding-top: 20px;
}

/*Other pages-------------------------------------------------------------------------------*/

/*Header for others pages*/
.pages {
    height: 120px;
    background: url(/static/media/cpa-tax-service-header2.5ae39f57.png) no-repeat;
}

.other_pages {
    margin: 50px auto;
    padding: 10px;
    clear: both;

}

.other_pages h1 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 2.2em;
    border-bottom: 1px solid black;
    display: block;
    padding: 10px;

}

.other_pages p {
    font-size: 1.2em;
    padding: 10px;
    text-align: justify;
}

/*About-US page---------------------------------------------*/

.about-us p {
    padding: 60px 30px 10px;
}

.about-us img {
    float: right;
    margin: 20px;
    padding: 10px 30px;
    width: 300px;
    height: 240px;

}

/*Service page-------------------------------------------------*/

.service-page-title {
    margin: 0 auto;
    padding: 20px 20%;
    clear: both;
    height: 60px;
    border-bottom: 1px solid black;

}

.service-page-title h1 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 2.2em;
    display: block;

}

/*Accounting-----------------------------------------------------------*/

.service-list {
    margin: 0 auto;
    padding: 20px 0;
    /*height: 550px;*/
    background-color: white;
    border-bottom: 1px solid #ededed;
}

.servist-list-second {
    height: 680px;
}

.list {
    width: 70%;
    margin: 1px auto;
    padding-top: 30px;
}

.service-header {
    padding: 15px 10px;
    color: black;
    font-weight: 500;
    font-size: 2em;
    vertical-align: center;
}

.service-list h1 {
    padding-top: 10px;
    margin-bottom: 20px;
}

.service-list h2 {
    color: black;
    padding-top: 10px;
}

.service-list p, li {
    color: black;
}

.service-list p {
    padding: 5px 10px;
    width: 70%;
    text-align: justify;
    margin: 10px;
}

.service-list img {
    float: right;
    border: 1px solid rgb(220, 220, 220);
    margin-left: 20px;
}

.service-list:last-of-type {
    margin-bottom: 50px;
    border: none;
}

.button-consultation {
    margin: 20px;
}

.button-consultation a {
    height: 40px;
    width: 200px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 20px;
    text-decoration: none;
    color: black;
    font-size: 1.2em;
}

.button-consultation a:hover {
    background-color: rgba(225, 225, 225, .9);
}

.button-consultation2 a:hover {
    background-color: rgba(255, 255, 255, .7);
}
.services-list {
    padding-bottom: 1px;
}

/*Contact US page---------------------------------------------------------*/

.contact {

}

/*#map {*/
/*    width: 500px;*/
/*    height: 400px;*/
/*    float: left;*/
/*    margin: 0 auto 50px;*/
/*    -webkit-filter: grayscale(60%);*/
/*    filter: grayscale(60%);*/
/*}*/

.contact-info {
    display: flex;
    justify-content: center;
}

.contact-info h1 {
    padding: 0;
    margin: 0;
}

.contact-info h2 {
    color: black;

}

.contact-info h3 {
    margin-top: 50px;
    font-size: 1em;
    text-align: center;
}

.contact-info h4 {
    font-weight: 500;
    font-size: 1.5em;
    padding-bottom: 20px;
}

.terry-photo {
    width: 200px;
    height: auto;
    margin: 20px;
}
.terry-photo img {
    width: 100%;
    height: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

div.contact1 {

}

div.contact2 {
    display: flex;
    flex-direction: column;
    line-height: 1.5em;
    margin-top: 50px;
    margin-left: 20px;

}

/*Form styles------------------------------------------------------------------*/

div.erro {
    color: red;
    font-size: 0.7em;
    font-weight: 500;
    font-family: 'Open Sans', sans-serif;
}

span.erro {
    color: red;
    font-size: 0.7em;
    font-weight: 500;
    font-family: 'Open Sans', sans-serif;
}

form {
    margin-top: 20px;
    font-size: 1.3em;
    font-weight: 700;
}

input[type=text], input[type=email] {
    width: 40%;
    padding: 12px 20px;
    margin: 8px;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
}

input[type=date], input[type=time] {
    width: 20%;
    padding: 10px 20px;
    margin: 8px;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;

}

input[type=text]:focus, input[type=email]:focus {
    background-color: #d9d0bd;
}

select {
    width: 40%;
    padding: 12px 20px;
    margin: 10px 8px 8px;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
}

textarea {
    width: 40%;
    height: 150px;
    padding: 12px 20px;
    margin: 8px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    resize: none;
    font-family: 'Open Sans', sans-serif;

}

input[type=button], input[type=submit], input[type=reset] {
    background-color: #555;
    border: none;
    color: white;
    padding: 16px 32px;
    text-decoration: none;
    margin: 4px 2px;
    cursor: pointer;
    font-size: 1em;
}

input[type=button]:hover, input[type=submit]:hover, input[type=reset]:hover {
    background-color: rgba(0, 0, 0, 0.5);
}

/*Pop up styles---------------------------------------------------------------------------------------*/

.popup {
    width: 100%;
    height: 100%;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.75);
    color: black;
    font-weight: 700;
    z-index: 2;
}

/* Inner */

.popup-inner {
    max-width: 750px;
    width: 90%;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 1);
    border-radius: 3px;
    background: #fff;
    text-align: center;

}

.popup-inner a {
    text-decoration: none;
    color: #d9d0bd;
}

.popup-inner h1 {
    padding: 10px;
    font-weight: 500;
    font-size: 2em;
}

.popup-inner h2 {
    color: black;
    margin-top: 10px;
}

.choose-website {
    display: inline-block;
    position: relative;
    padding: 0 20px;
    margin: auto;

}

.choose-website aside {
    float: left;
    padding: 10px;
    margin: 20px;
}

/* Close Button */
.popup-close {
    width: 30px;
    height: 30px;
    padding-top: 4px;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    transition: all ease 250ms;
    transform: translate(50%, -50%);
    border-radius: 1000px;
    background: rgba(0, 0, 0, 0.8);
    font-size: 20px;
    text-align: center;
    line-height: 100%;
    color: #fff;
}

.popup-close:hover {
    transform: translate(50%, -50%) rotate(180deg);
    background: rgba(0, 0, 0, 1);
    text-decoration: none;
}

.promotion-banner {
    /*background-image: url(../img/promotion-banner-desktop.png);*/
    /*width: 100%;*/
    /*height: 200px;*/
    margin: 20px auto;
}

.promotion-banner__desktop {
    width: 100%;
}

.promotion-banner__mobile {
    width: 100%;
    display: none;
}

/*Media Queries---------------------------------------------------------------------------------------------------------------------------*/
/*Media Queries---------------------------------------------------------------------------------------------------------------------------*/

@media screen and (max-width: 1370px) {

    /*Header -------------------------------------------------------------------------------------*/
    #first-page {
        height: 500px; background: url(/static/media/homeBanner1250.1d157485.png) no-repeat;
    }

    .main-page {
        max-width: 1250px; min-height: 200px; margin: 0 auto; position: relative; background-color: white;
    }
    .logo {
        position: absolute; top: 20px; left:10%;
    }
    nav {
        font-size: 1.2em;
    }
    .page-footer {
        width: 100%;
        position: absolute;
    }
    /*Service Page-----------------------------------------*/

    /*.service-list {*/
        /*height: 600px;*/
    /*}*/
    /*.servist-list-second {*/
        /*height: 720px;*/
    /*}*/
    /*Header Service page*/
    .pages {
        height:117px;
        background: url(/static/media/cpa-tax-service-header.ec20c9af.png) no-repeat;
    }

}
/*-----------------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 1250px) {
    nav {
        font-size: 1em;
    }
    /*Section Star up*/

    div.startups-text { width: 43%;
    }

    /*Service page*/
    .pages {
        height:112px;
        background: url(/static/media/cpa-tax-service-header.ec20c9af.png) no-repeat;
    }
    .list {
        width:80%;
    }


}
/*-----------------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 1090px) {


    /*Header*/
    .logo h1 {font-size: 1.2em;}


    /*Navigation Bar*/
    nav { font-size: 1em;}

    nav > ul {padding-left: 38%; padding-top: 50px}

    /*Section Main One Element styles */

    div.service {

        height: 285px;
    }

    /*Section Star up*/

    .startups {
        height: 380px;
        background:  rgb(240,240,240) url(/static/media/startups.31c99bbf.png) no-repeat top 50% left 100%;
        background-size: 450px 277px;
    }

    div.startups-text { height: 150px; width: 42%;
    }
    div.startups-text h1 { font-size: 1.3em;
    }

    /*Section Tax*/
    div.tax-text { width: 30%;height: 110px;
        position: relative; top: 180px; left:64%;
        padding-left: 5px;
    }

    #tax-log { font-size: 1.5em;}

    div.tax-text h1 {
        font-size: 1.6em;
    }
    /*Service page*/
    .pages {
        height:147px;
        background: url(/static/media/cpa-tax-service-header.ec20c9af.png) no-repeat;
    }

    .service-page-title {
        padding: 20px 10%;
    }

    /*.service-list {*/
        /*height: 650px;*/
    /*}*/
    .list {
        width:90%;
    }
    .servist-list-second {
        height: 700px;
    }

    /*Other pages Contact-us*/

    #map {float:none;
        width: 70%;
        height: 400px;}
}
/*---------------------------------------------------------------------------------------------------------*/

@media screen and (max-width: 960px) {

    /*Navigation Bar*/
    nav > ul {padding-left: 7%; padding-top: 50px}

    /*Section Main One Element styles */
    div.service { height: 340px; margin:15px 5px;}


    /*Section Star up*/
    .startups {
        height: 380px;
        background:  rgb(240,240,240) url(/static/media/startups.31c99bbf.png) no-repeat top 50% left 100%;
        background-size: 380px 234px;
    }
    div.startups-text { height: 150px; width: 44%;
    }
    div.startups-text h1 { font-size: 1.1em;
    }

    /*Section Tax*/
    div.tax-text { width: 270px; height: 80px;
        position: relative; top: 90px; left:3%;
    }

    div.tax-text h1 {
        font-size: 1em;
    }
    #tax-log { font-size: 1.3em;}

    /*Service page*/
    /*.servist-list-second {*/
        /*height: 750px;*/
        /*margin-top: 20px;*/
    /*}*/


    .popup {
        z-index: 0;
    }

}


/*-------------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 760px) {


    /*Navigation Bar*/
    nav > ul {padding-left: 1%; padding-top: 50px}

    /*Section Main One Element styles */
    div.service { height: 400px; margin:15px 5px;}

    /*Section Star up*/

    .startups {
        height: 580px;
        background:  rgb(240,240,240) url(/static/media/startups.31c99bbf.png) no-repeat top 10% left 50%;
        background-size: 500px 308px;
    }
    div.startups-text { height: 150px; width: 68%;
        position: relative; top: 350px; left:0%;
        margin:0 auto;
    }
    div.startups-text h1 { font-size: 1.3em;
    }

    /*Other pages Service-page*/

    .service-list img {
        display: none;
    }
    .service-list p {
        width: 90%;
    }


    .service-list {
        height: 590px;
    }
    .servist-list-second {
        height: 700px;
    }
    /*Other pages About-us-page*/

    .about-us img {
        float:none;
        width: 80%;
        height: auto;
        padding: auto;
        margin: auto;
    }

    .promotion-banner__desktop {
        display: none;
    }

    .promotion-banner__mobile {
       display: block;
    }




}

@media screen and (max-width: 685px) {

    .popup {
        z-index: 2;
    }

    .popup-inner {
        height: 550px;

    }

    .choose-website {
        margin:auto;
        left:23%;
    }


}

@media screen and (max-width: 599px) {
    /*---------------------------------------------------------------------------------------------------------*/


    /*Header*/
    #first-page {
        height: 600px; background: url(/static/media/homeBanner1920.b1c37c6e.png) no-repeat;
    }
    /*Header advert*/
    .advert {
        position:absolute;  top:400px;
        margin: auto 0px;
        padding: 30px 5px;
        width:350px;
    }
    .advert h1 { font-size: 1.8em;  margin: 0; padding-left: 10px;
    }

    /*Navigation Bar*/
    nav > ul > li { float: none;}


    /*Submenu buttons*/

    nav ul li:hover > ul{transition-delay: .65s; display:block; position: absolute; top: 147px; left:94px;
        background-color: rgba(0,0,0,.8);
        z-index: 1;
    }
    nav ul li:visited > ul {display: none}

    /*Service-list sections */
    section.servicelist aside {width: 100%; float:none; text-align: center; margin:0;
    }
    div.service { height: 255px; margin: 0;}


    /*Section Star up*/

    .startups {
        height: 520px;
        margin:0;
        background:  rgb(240,240,240) url(/static/media/startups.31c99bbf.png) no-repeat top 10%;
        background-size: 375px 234px;
    }
    div.startups-text { height: 150px; width: 80%;
        position: relative; top: 280px; left:0;
        margin:0 auto;
    }
    div.startups-text h1 { font-size: 1.1em;
    }


    /*Section Tax*/
    .tax {
        height: 400px;
        margin:0 auto;
        margin-top: 20px;
        background:url(/static/media/terry-cpa-accounting420.e84f2596.png) no-repeat top 10px;
        background-size: 600px 240px;
    }
    div.tax-text { width: 90%; height: 80px;
        padding: 10px;
        margin: 0 auto;
        position: relative; top: 280px; left:0;
        background-color: rgba(10,10,10,.8);
    }

    div.tax-text h1 {
        font-size: 1.3em;
    }
    #tax-log { font-size: 1.3em;}


    /*Footer*/
    .page-footer1 {
        padding-left: 0;
        font-size: 1em;
    }

    /*Other pages Service-page*/
    .pages {
        height:336px;
        background: url(/static/media/homeBanner1250.1d157485.png) no-repeat;
    }

    /*.service-list {*/
        /*height: 550px;*/
    /*}*/
    .servist-list-second {
        height: 790px;
    }
    /*Other pages Contact-us*/

    .contact-info {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
        padding: 0;
        text-align: center;
    }
    div.contact1 {
        flex: 1 1;
        text-align: center;
    }
    #terry-photo {
        margin: auto;
        float:none;
        text-align: center;
        margin-top: 10px;
    }

    div.contact2 {
        flex: 1 1;
        margin: auto;
        padding: auto;
        margin-top: 20px;
    }

    /*Form styles*/

    input[type=text] {
        width: 80%;
    }
    #form-title {
        font-size: 1.5em;
    }

    /*Pop-up styles*/

    .popup-inner {
        height: 600px;
    }

    .choose-website {
        margin:5px auto;
        left:15%;
    }

}


@media screen and (max-width: 425px) {
    /*-----------------------------------------------------------------------------------------------------*/

    /*Header advert*/
    .advert {
        width:340px;
    }

    /*Section Star up*/
    div.startups-text h1 { font-size: 0.9em;
    }

    /*Footer*/
    .page-footer1 {
        padding-left: 0;
        font-size: 1em;
    }

    /*Other pages Service-page*/
    /*.service-list {*/
        /*height: 610px;*/
    /*}*/
    .servist-list-second {
        height: 830px;
    }
    .service-page-title h1 {
        font-size: 1.8em;
    }

    /*Pop-up styles*/

    .popup-inner {
        height: 510px;
    }

    .popup-inner h1 {
        font-size: 1.3em;
    }
    .popup-inner h2 {
        font-size: 1.3em;
    }
    .choose-website {
        margin:auto;
        left:13%;
    }



}

@media screen and (max-width: 375px) {
    /*--------------------------------------------------------------------------------------------*/

    .logo h1 { font-size: 1.5em;}

    .logo h2 { font-size: 1.1em;}

    /*Header advert*/
    .advert {
        position:absolute;  top:380px;
        margin: auto 0px;
        padding: 10px 1px;
        width:270px;
    }
    .advert h1 { font-size: 1.2em;  margin: 0; padding-left: 10px;}
    .advert h2 { font-size: 1.1em;}


    /*Section Star up*/

    div.startups-text h1 { font-size: 0.9em;}
    div.startups-text p {font-size: .8em;}

    div.startups-text {width:300px;}



    /*Other pages Service-page*/
    .service-page-title {
        padding: 20px 5%;
    }
    .service-page-title h1 {
        font-size: 1.6em;
    }


    .service-list h2 {
        font-size: 1.2em;
    }
    .service-list p {
        padding: 5px;
        width: 95%;
    }

    /*.service-list {*/
        /*height: 650px;*/
    /*}*/
    .servist-list-second {
        height: 900px;
    }

    .button-consultation a {
        height: 40px; width: 120px;
        border: 1px solid black;
        border-radius: 5px;
        padding: 10px;
        text-decoration: none;
        color:black;
        font-size: 1em;
    }

    /*Other pages About-us-Page*/

    .about-us p {
        font-size: 1em;
    }

    /*Form styles*/
    input[type=text] {
        width: 90%;
    }
    #form-title {
        font-size: 1.3em;
    }
    select {
        width: 90%;
    }
    textarea {
        width: 90%;
    }


    /*Pop-up styles*/

    .popup-inner {
        height: 460px;
    }

    .popup-inner h1 {
        font-size: 1.2em;
    }
    .popup-inner h2 {
        font-size: 1em;
    }
    .choose-website {
        margin:auto;
        left: 10%;
    }

}

