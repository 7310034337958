@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300italic,700,700italic);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display:700italic);

/*All styles*/
html, body, header, footer, aside, article, p, h1, h2, h3, h4, div {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: #555;
    margin: 0;
    padding: 0;
}

article, aside, nav, section, header, footer {
    display: block;
}

#page {
    max-width: 1250px;
    min-height: 700px;
    margin: 0 auto;
    position: relative;
    background-color: white;
}

.page {
    max-width: 1250px;
    margin: 0 auto;
    position: relative;
    background-color: white;
    padding: 0;
}

.borderis {
    border-bottom: 1px solid black;
    margin: 0;
    clear: both;
}

.full-page {
    width: 100%;
    background-color: rgb(240, 240, 240);
    padding: 0;
    margin: 0;
}

/*Header -------------------------------------------------------------------------------------*/
#first-page {
    height: 600px;
    background: url(../img/homeBanner1920.png) no-repeat;
}

/*	background-size: contain; */

.logo {
    min-width: 260px;
    position: absolute;
    top: 20px;
    left: 20%;
    z-index: 1;
    padding: 10px;
    text-align: left;
}

.logo h1, h2 {
    color: #d9d0bd;
    font-weight: 500;
}

.logo h2 {
    font-style: italic;
    padding-left: 0;
    margin-left: 1px;
    position: relative;
    left: 0;
}

.advert {
    position: absolute;
    top: 320px;
    right: 0;
    border: 2px solid white;
    border-right: 0;
    padding: 20px;
    background-color: rgba(0, 0, 0, .7);
    width: 42%;
}

.advert h1 {
    line-height: 1.2em;
    margin: 5px;
    color: white;
}

.advert h2 {
    font-style: italic;
    display: inline-block;
    padding: 2px 30px;
}

/*Navigation -----------------------------------------------------------------------------------------*/

nav {
    background-color: rgba(0, 0, 0, .7);
    position: absolute;
    top: 0;
    left: 0;
    padding: 50px 0 0 2%;
    width: 98%;
    font-size: 1.4em;
}

nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

nav ul li:hover {
    background-color: rgba(255, 255, 255, .30);
    transition: background-color .65s;
    z-index: 10;
}

nav ul li:hover > ul {
    transition-delay: .65s;
    display: block;
}

nav ul li a {
    display: inline-block;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    position: relative;
    width: 110px;
}

nav ul li a:visited {
    color: #fff;
}

nav ul li:hover {
    background-color: rgba(255, 255, 255, .30);
    transition: background-color .65s;
}

nav ul ul {
    position: absolute;
    top: 100%;
    background-color: rgba(0, 0, 0, .65);
    display: none;
}

nav ul ul li {
    position: relative;
}

nav ul ul ul {
    left: 100%;
    top: 0;
}

/* Top-level-menu-buttons*/

nav > ul {
    padding-left: 45%;
    padding-top: 15px
}

nav > ul > li {
    float: left;
}

nav > ul > li > a {
    width: auto;
    padding: 10px 20px 15px 20px;
}

nav::after {
    content: '';
    display: block;
    clear: both;
}

nav a[aria-haspopup="true"]::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: auto;
    right: auto;
    bottom: 6px;
    left: 45px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid white;

}

/*Service-list sections ------------------------------------------------------------------------- */

section.servicelist {
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 0;
}

section.servicelist::after {
    content: '';
    display: block;
    clear: both;
}

section.servicelist aside {
    width: 33%;
    float: left;
    text-align: center;
}

section.servicelist aside p {
    margin-top: 10px;
    text-align: center;
}

/*Section Service List inside Elements ------------------------------------------------------------*/

div.service {
    margin: 15px;
    padding-right: 20px;
    padding-left: 10px;
    background: #fafafa no-repeat center top;
    background-size: 75px 75px;
    height: 250px;
    transition: .3s;
}

.service:hover {
    background-color: rgba(0, 0, 0, .1);
}

.service h3 {
    padding-top: 5px;
}

div.service a {
    color: rgba(0, 0, 0, .7);
    text-decoration: none;
}

div.service a:hover {
    text-decoration: underline;
}

/* Icons service section elements--------------------------------------------------------------*/
.icon {
    padding: 20px;
}

.icon2 {
    color: rgba(0, 0, 0, .8);

}

/*Startups Section Banner 1 -------------------------------------------------------------*/

.startups {
    height: 480px;
    clear: both;
    margin: 50px auto 0;
    background: rgb(240, 240, 240) url(../img/startups.png) no-repeat top 40px left 550px;
}

div.startups-text {
    height: 150px;
    width: 38%;
    text-align: justify;
    color: black;
    padding: 10px;
    position: relative;
    top: 80px;
    left: 30px;
}

div.startups-text h1 {
    font-size: 1.5em;
    font-family: 'Open Sans';
    font-weight: 500;
    text-align: left;
    padding: 5px;
    display: block;
    border-bottom: 1px solid black;
}

div.startups-text h1 a {
    text-decoration: none;
    color: black;
}

div.startups-text p {
    padding: 20px 20px 20px 0;
}

div.startups-text a {
    color: black;
    text-decoration: none;
}

/*Tax Section Banner 2---------------------------------------------------*/

.tax {
    height: 500px;
    clear: both;
    margin: 20px auto 50px;
    background: url(../img/terry-cpa-accounting.png) no-repeat;
}

div.tax-text {
    height: 160px;
    width: 40%;
    text-align: justify;
    padding: 10px 10px 10px 20px;
    position: relative;
    top: 180px;
    left: 55%;
    background-color: rgba(10, 10, 10, .7);
    border-radius: 5px;
}

div.tax-text h1 {
    font-size: 2.8em;
    font-family: 'Playfair Display', serif;
    text-align: center;
    display: block;
    color: rgba(250, 250, 250, .9);
}

#tax-log {
    font-family: 'Playfair Display', serif;
    color: #d9d0bd;
    font-size: 1.8em;
    border-bottom: 1px solid #d9d0bd;
    text-align: left;
    padding-bottom: 5px;
}

/*Footer---------------------------------------------------------*/

.page-footer {
    height: 80px;
    color: white;
    background-color: rgba(0, 0, 0, .8);
    width: 100%;
    text-align: center;
}

.page-footer p {
    padding-top: 10px;
    text-decoration: underline;
}

.page-footer1 {
    max-width: 1250px;
    margin: 0 auto;
    padding-left: 50px;
    font-size: 1.2em;
}

.page-footer h2 {
    padding-top: 20px;
}

/*Other pages-------------------------------------------------------------------------------*/

/*Header for others pages*/
.pages {
    height: 120px;
    background: url(../img/cpa-tax-service-header2.png) no-repeat;
}

.other_pages {
    margin: 50px auto;
    padding: 10px;
    clear: both;

}

.other_pages h1 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 2.2em;
    border-bottom: 1px solid black;
    display: block;
    padding: 10px;

}

.other_pages p {
    font-size: 1.2em;
    padding: 10px;
    text-align: justify;
}

/*About-US page---------------------------------------------*/

.about-us p {
    padding: 60px 30px 10px;
}

.about-us img {
    float: right;
    margin: 20px;
    padding: 10px 30px;
    width: 300px;
    height: 240px;

}

/*Service page-------------------------------------------------*/

.service-page-title {
    margin: 0 auto;
    padding: 20px 20%;
    clear: both;
    height: 60px;
    border-bottom: 1px solid black;

}

.service-page-title h1 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 2.2em;
    display: block;

}

/*Accounting-----------------------------------------------------------*/

.service-list {
    margin: 0 auto;
    padding: 20px 0;
    /*height: 550px;*/
    background-color: white;
    border-bottom: 1px solid #ededed;
}

.servist-list-second {
    height: 680px;
}

.list {
    width: 70%;
    margin: 1px auto;
    padding-top: 30px;
}

.service-header {
    padding: 15px 10px;
    color: black;
    font-weight: 500;
    font-size: 2em;
    vertical-align: center;
}

.service-list h1 {
    padding-top: 10px;
    margin-bottom: 20px;
}

.service-list h2 {
    color: black;
    padding-top: 10px;
}

.service-list p, li {
    color: black;
}

.service-list p {
    padding: 5px 10px;
    width: 70%;
    text-align: justify;
    margin: 10px;
}

.service-list img {
    float: right;
    border: 1px solid rgb(220, 220, 220);
    margin-left: 20px;
}

.service-list:last-of-type {
    margin-bottom: 50px;
    border: none;
}

.button-consultation {
    margin: 20px;
}

.button-consultation a {
    height: 40px;
    width: 200px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 20px;
    text-decoration: none;
    color: black;
    font-size: 1.2em;
}

.button-consultation a:hover {
    background-color: rgba(225, 225, 225, .9);
}

.button-consultation2 a:hover {
    background-color: rgba(255, 255, 255, .7);
}
.services-list {
    padding-bottom: 1px;
}

/*Contact US page---------------------------------------------------------*/

.contact {

}

/*#map {*/
/*    width: 500px;*/
/*    height: 400px;*/
/*    float: left;*/
/*    margin: 0 auto 50px;*/
/*    -webkit-filter: grayscale(60%);*/
/*    filter: grayscale(60%);*/
/*}*/

.contact-info {
    display: flex;
    justify-content: center;
}

.contact-info h1 {
    padding: 0;
    margin: 0;
}

.contact-info h2 {
    color: black;

}

.contact-info h3 {
    margin-top: 50px;
    font-size: 1em;
    text-align: center;
}

.contact-info h4 {
    font-weight: 500;
    font-size: 1.5em;
    padding-bottom: 20px;
}

.terry-photo {
    width: 200px;
    height: auto;
    margin: 20px;
}
.terry-photo img {
    width: 100%;
    height: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

div.contact1 {

}

div.contact2 {
    display: flex;
    flex-direction: column;
    line-height: 1.5em;
    margin-top: 50px;
    margin-left: 20px;

}

/*Form styles------------------------------------------------------------------*/

div.erro {
    color: red;
    font-size: 0.7em;
    font-weight: 500;
    font-family: 'Open Sans', sans-serif;
}

span.erro {
    color: red;
    font-size: 0.7em;
    font-weight: 500;
    font-family: 'Open Sans', sans-serif;
}

form {
    margin-top: 20px;
    font-size: 1.3em;
    font-weight: 700;
}

input[type=text], input[type=email] {
    width: 40%;
    padding: 12px 20px;
    margin: 8px;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
}

input[type=date], input[type=time] {
    width: 20%;
    padding: 10px 20px;
    margin: 8px;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;

}

input[type=text]:focus, input[type=email]:focus {
    background-color: #d9d0bd;
}

select {
    width: 40%;
    padding: 12px 20px;
    margin: 10px 8px 8px;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
}

textarea {
    width: 40%;
    height: 150px;
    padding: 12px 20px;
    margin: 8px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    resize: none;
    font-family: 'Open Sans', sans-serif;

}

input[type=button], input[type=submit], input[type=reset] {
    background-color: #555;
    border: none;
    color: white;
    padding: 16px 32px;
    text-decoration: none;
    margin: 4px 2px;
    cursor: pointer;
    font-size: 1em;
}

input[type=button]:hover, input[type=submit]:hover, input[type=reset]:hover {
    background-color: rgba(0, 0, 0, 0.5);
}

/*Pop up styles---------------------------------------------------------------------------------------*/

.popup {
    width: 100%;
    height: 100%;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.75);
    color: black;
    font-weight: 700;
    z-index: 2;
}

/* Inner */

.popup-inner {
    max-width: 750px;
    width: 90%;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 1);
    border-radius: 3px;
    background: #fff;
    text-align: center;

}

.popup-inner a {
    text-decoration: none;
    color: #d9d0bd;
}

.popup-inner h1 {
    padding: 10px;
    font-weight: 500;
    font-size: 2em;
}

.popup-inner h2 {
    color: black;
    margin-top: 10px;
}

.choose-website {
    display: inline-block;
    position: relative;
    padding: 0 20px;
    margin: auto;

}

.choose-website aside {
    float: left;
    padding: 10px;
    margin: 20px;
}

/* Close Button */
.popup-close {
    width: 30px;
    height: 30px;
    padding-top: 4px;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    transition: all ease 250ms;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    border-radius: 1000px;
    background: rgba(0, 0, 0, 0.8);
    font-size: 20px;
    text-align: center;
    line-height: 100%;
    color: #fff;
}

.popup-close:hover {
    -webkit-transform: translate(50%, -50%) rotate(180deg);
    transform: translate(50%, -50%) rotate(180deg);
    background: rgba(0, 0, 0, 1);
    text-decoration: none;
}

.promotion-banner {
    /*background-image: url(../img/promotion-banner-desktop.png);*/
    /*width: 100%;*/
    /*height: 200px;*/
    margin: 20px auto;
}

.promotion-banner__desktop {
    width: 100%;
}

.promotion-banner__mobile {
    width: 100%;
    display: none;
}

/*Media Queries---------------------------------------------------------------------------------------------------------------------------*/
/*Media Queries---------------------------------------------------------------------------------------------------------------------------*/
