@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300italic,700,700italic);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display:700italic);

@media screen and (max-width: 1370px) {

    /*Header -------------------------------------------------------------------------------------*/
    #first-page {
        height: 500px; background: url(../img/homeBanner1250.png) no-repeat;
    }

    .main-page {
        max-width: 1250px; min-height: 200px; margin: 0 auto; position: relative; background-color: white;
    }
    .logo {
        position: absolute; top: 20px; left:10%;
    }
    nav {
        font-size: 1.2em;
    }
    .page-footer {
        width: 100%;
        position: absolute;
    }
    /*Service Page-----------------------------------------*/

    /*.service-list {*/
        /*height: 600px;*/
    /*}*/
    /*.servist-list-second {*/
        /*height: 720px;*/
    /*}*/
    /*Header Service page*/
    .pages {
        height:117px;
        background: url(../img/cpa-tax-service-header.png) no-repeat;
    }

}
/*-----------------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 1250px) {
    nav {
        font-size: 1em;
    }
    /*Section Star up*/

    div.startups-text { width: 43%;
    }

    /*Service page*/
    .pages {
        height:112px;
        background: url(../img/cpa-tax-service-header.png) no-repeat;
    }
    .list {
        width:80%;
    }


}
/*-----------------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 1090px) {


    /*Header*/
    .logo h1 {font-size: 1.2em;}


    /*Navigation Bar*/
    nav { font-size: 1em;}

    nav > ul {padding-left: 38%; padding-top: 50px}

    /*Section Main One Element styles */

    div.service {

        height: 285px;
    }

    /*Section Star up*/

    .startups {
        height: 380px;
        background:  rgb(240,240,240) url(../img/startups.png) no-repeat top 50% left 100%;
        background-size: 450px 277px;
    }

    div.startups-text { height: 150px; width: 42%;
    }
    div.startups-text h1 { font-size: 1.3em;
    }

    /*Section Tax*/
    div.tax-text { width: 30%;height: 110px;
        position: relative; top: 180px; left:64%;
        padding-left: 5px;
    }

    #tax-log { font-size: 1.5em;}

    div.tax-text h1 {
        font-size: 1.6em;
    }
    /*Service page*/
    .pages {
        height:147px;
        background: url(../img/cpa-tax-service-header.png) no-repeat;
    }

    .service-page-title {
        padding: 20px 10%;
    }

    /*.service-list {*/
        /*height: 650px;*/
    /*}*/
    .list {
        width:90%;
    }
    .servist-list-second {
        height: 700px;
    }

    /*Other pages Contact-us*/

    #map {float:none;
        width: 70%;
        height: 400px;}
}
/*---------------------------------------------------------------------------------------------------------*/

@media screen and (max-width: 960px) {

    /*Navigation Bar*/
    nav > ul {padding-left: 7%; padding-top: 50px}

    /*Section Main One Element styles */
    div.service { height: 340px; margin:15px 5px;}


    /*Section Star up*/
    .startups {
        height: 380px;
        background:  rgb(240,240,240) url(../img/startups.png) no-repeat top 50% left 100%;
        background-size: 380px 234px;
    }
    div.startups-text { height: 150px; width: 44%;
    }
    div.startups-text h1 { font-size: 1.1em;
    }

    /*Section Tax*/
    div.tax-text { width: 270px; height: 80px;
        position: relative; top: 90px; left:3%;
    }

    div.tax-text h1 {
        font-size: 1em;
    }
    #tax-log { font-size: 1.3em;}

    /*Service page*/
    /*.servist-list-second {*/
        /*height: 750px;*/
        /*margin-top: 20px;*/
    /*}*/


    .popup {
        z-index: 0;
    }

}


/*-------------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 760px) {


    /*Navigation Bar*/
    nav > ul {padding-left: 1%; padding-top: 50px}

    /*Section Main One Element styles */
    div.service { height: 400px; margin:15px 5px;}

    /*Section Star up*/

    .startups {
        height: 580px;
        background:  rgb(240,240,240) url(../img/startups.png) no-repeat top 10% left 50%;
        background-size: 500px 308px;
    }
    div.startups-text { height: 150px; width: 68%;
        position: relative; top: 350px; left:0%;
        margin:0 auto;
    }
    div.startups-text h1 { font-size: 1.3em;
    }

    /*Other pages Service-page*/

    .service-list img {
        display: none;
    }
    .service-list p {
        width: 90%;
    }


    .service-list {
        height: 590px;
    }
    .servist-list-second {
        height: 700px;
    }
    /*Other pages About-us-page*/

    .about-us img {
        float:none;
        width: 80%;
        height: auto;
        padding: auto;
        margin: auto;
    }

    .promotion-banner__desktop {
        display: none;
    }

    .promotion-banner__mobile {
       display: block;
    }




}

@media screen and (max-width: 685px) {

    .popup {
        z-index: 2;
    }

    .popup-inner {
        height: 550px;

    }

    .choose-website {
        margin:auto;
        left:23%;
    }


}

@media screen and (max-width: 599px) {
    /*---------------------------------------------------------------------------------------------------------*/


    /*Header*/
    #first-page {
        height: 600px; background: url(../img/homeBanner1920.png) no-repeat;
    }
    /*Header advert*/
    .advert {
        position:absolute;  top:400px;
        margin: auto 0px;
        padding: 30px 5px;
        width:350px;
    }
    .advert h1 { font-size: 1.8em;  margin: 0; padding-left: 10px;
    }

    /*Navigation Bar*/
    nav > ul > li { float: none;}


    /*Submenu buttons*/

    nav ul li:hover > ul{transition-delay: .65s; display:block; position: absolute; top: 147px; left:94px;
        background-color: rgba(0,0,0,.8);
        z-index: 1;
    }
    nav ul li:visited > ul {display: none}

    /*Service-list sections */
    section.servicelist aside {width: 100%; float:none; text-align: center; margin:0;
    }
    div.service { height: 255px; margin: 0;}


    /*Section Star up*/

    .startups {
        height: 520px;
        margin:0;
        background:  rgb(240,240,240) url(../img/startups.png) no-repeat top 10%;
        background-size: 375px 234px;
    }
    div.startups-text { height: 150px; width: 80%;
        position: relative; top: 280px; left:0;
        margin:0 auto;
    }
    div.startups-text h1 { font-size: 1.1em;
    }


    /*Section Tax*/
    .tax {
        height: 400px;
        margin:0 auto;
        margin-top: 20px;
        background:url(../img/terry-cpa-accounting420.png) no-repeat top 10px;
        background-size: 600px 240px;
    }
    div.tax-text { width: 90%; height: 80px;
        padding: 10px;
        margin: 0 auto;
        position: relative; top: 280px; left:0;
        background-color: rgba(10,10,10,.8);
    }

    div.tax-text h1 {
        font-size: 1.3em;
    }
    #tax-log { font-size: 1.3em;}


    /*Footer*/
    .page-footer1 {
        padding-left: 0;
        font-size: 1em;
    }

    /*Other pages Service-page*/
    .pages {
        height:336px;
        background: url(../img/homeBanner1250.png) no-repeat;
    }

    /*.service-list {*/
        /*height: 550px;*/
    /*}*/
    .servist-list-second {
        height: 790px;
    }
    /*Other pages Contact-us*/

    .contact-info {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
        padding: 0;
        text-align: center;
    }
    div.contact1 {
        flex: 1;
        text-align: center;
    }
    #terry-photo {
        margin: auto;
        float:none;
        text-align: center;
        margin-top: 10px;
    }

    div.contact2 {
        flex: 1;
        margin: auto;
        padding: auto;
        margin-top: 20px;
    }

    /*Form styles*/

    input[type=text] {
        width: 80%;
    }
    #form-title {
        font-size: 1.5em;
    }

    /*Pop-up styles*/

    .popup-inner {
        height: 600px;
    }

    .choose-website {
        margin:5px auto;
        left:15%;
    }

}


@media screen and (max-width: 425px) {
    /*-----------------------------------------------------------------------------------------------------*/

    /*Header advert*/
    .advert {
        width:340px;
    }

    /*Section Star up*/
    div.startups-text h1 { font-size: 0.9em;
    }

    /*Footer*/
    .page-footer1 {
        padding-left: 0;
        font-size: 1em;
    }

    /*Other pages Service-page*/
    /*.service-list {*/
        /*height: 610px;*/
    /*}*/
    .servist-list-second {
        height: 830px;
    }
    .service-page-title h1 {
        font-size: 1.8em;
    }

    /*Pop-up styles*/

    .popup-inner {
        height: 510px;
    }

    .popup-inner h1 {
        font-size: 1.3em;
    }
    .popup-inner h2 {
        font-size: 1.3em;
    }
    .choose-website {
        margin:auto;
        left:13%;
    }



}

@media screen and (max-width: 375px) {
    /*--------------------------------------------------------------------------------------------*/

    .logo h1 { font-size: 1.5em;}

    .logo h2 { font-size: 1.1em;}

    /*Header advert*/
    .advert {
        position:absolute;  top:380px;
        margin: auto 0px;
        padding: 10px 1px;
        width:270px;
    }
    .advert h1 { font-size: 1.2em;  margin: 0; padding-left: 10px;}
    .advert h2 { font-size: 1.1em;}


    /*Section Star up*/

    div.startups-text h1 { font-size: 0.9em;}
    div.startups-text p {font-size: .8em;}

    div.startups-text {width:300px;}



    /*Other pages Service-page*/
    .service-page-title {
        padding: 20px 5%;
    }
    .service-page-title h1 {
        font-size: 1.6em;
    }


    .service-list h2 {
        font-size: 1.2em;
    }
    .service-list p {
        padding: 5px;
        width: 95%;
    }

    /*.service-list {*/
        /*height: 650px;*/
    /*}*/
    .servist-list-second {
        height: 900px;
    }

    .button-consultation a {
        height: 40px; width: 120px;
        border: 1px solid black;
        border-radius: 5px;
        padding: 10px;
        text-decoration: none;
        color:black;
        font-size: 1em;
    }

    /*Other pages About-us-Page*/

    .about-us p {
        font-size: 1em;
    }

    /*Form styles*/
    input[type=text] {
        width: 90%;
    }
    #form-title {
        font-size: 1.3em;
    }
    select {
        width: 90%;
    }
    textarea {
        width: 90%;
    }


    /*Pop-up styles*/

    .popup-inner {
        height: 460px;
    }

    .popup-inner h1 {
        font-size: 1.2em;
    }
    .popup-inner h2 {
        font-size: 1em;
    }
    .choose-website {
        margin:auto;
        left: 10%;
    }

}
